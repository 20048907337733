import React from 'react'
import withCommonSectionProps from '../../../../../../hoc/withCommonSectionProps'
import { HomeEquitySectionsProps } from '../..'
import SectionComponent from 'src/components/SectionComponent'
import { orange, sand } from 'src/styles/newColors'
import * as S from './style'

import { InterP } from 'src/components/Text/BodyText'
import { H1Citrina } from 'src/components/Text/Headings'
import PayrollSimulatorCitrina from 'src/components/PayrollSimulator/PayrollSimulatorCitrina'

const TEXT_CONTENT = {
  heading: 'Empréstimo com Garantia de Imóvel Inter',
  body: 'Receba a partir de R$70 mil com o Home Equity e aproveite o crédito com as menores taxas do mercado.',
}

const EmprestimoComGarantia = ({ id }: HomeEquitySectionsProps) => {
  return (
    <SectionComponent
      id={id}
      SectionStyles='pt-5 pb-2 pt-xl-0'
      bgColor={sand}
      minHeight={{ sm: '509px', md: '336px', lg: '516px', xl: '606px', xxl: '606px' }}
    >
      <div className='col-12 col-md-6'>
        <H1Citrina className='mb-3 mb-md-4' color={orange.dark}>{TEXT_CONTENT.heading}</H1Citrina>
        <InterP fontSize={{ lg: '18px' }} color={orange.dark}>{TEXT_CONTENT.body}</InterP>
      </div>
      <div className='col-12 col-md-6'>
        <div className='d-flex justify-content-center'>
          <S.GraphismBg>
            <PayrollSimulatorCitrina
              page='home_equity'
              sectionNumber='dobra_1'
              sectionName='Home Equity: quanto custa sua liberdade financeira?'
            />
          </S.GraphismBg>
        </div>
      </div>
    </SectionComponent>
  )
}

export default withCommonSectionProps(EmprestimoComGarantia)
