import { breakpoints } from 'src/styles/breakpoints'
import { graphite } from 'src/styles/newColors'
import styled from 'styled-components'

export const ListModificator = styled.ul`
  padding: 0;
  margin: 0;
  margin-top: 24px;
  width: 100%;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;

  & .list-item {
    font-family: 'Citrina', sans-serif;
    height: 75px;
    width: 152px;
    padding: 16px;
    border-radius: 16px 8px 16px 8px;
    background-color: ${graphite};
    color: #fff;
    display: flex;
    text-align: center;
    align-items: center;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }

  @media (min-width: ${breakpoints.md}) {
    justify-content: unset;
    gap: 16px;
    & .list-item {
      width: 160px;
      height: 80px;
      font-size: 13px;
      line-height: 15px;
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    margin-bottom: 24px;
    & .list-item {
      width: 192px;
      height: 96px;
      font-size: 17px;
      line-height: 22px;
    }
  }
`

export const CtaModificator = styled.div`
  a {
    width: 100%;
  }
`
