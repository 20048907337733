import React from 'react'
import { HomeEquitySectionsProps } from '../..'
import withCommonSectionProps from '../../../../../../hoc/withCommonSectionProps'
import SectionComponent from 'src/components/SectionComponent'
import { grayscale } from 'src/styles/colors'
import { H2Citrina } from 'src/components/Text/Headings'
import { InterP } from 'src/components/Text/BodyText'
import * as S from './style'
import { NoDecorationLink } from '../../style'
import { Button } from '@interco/inter-ui/components/Button'
import { WIDTH_LG, WIDTH_MD } from 'src/styles/breakpoints'
import Picture from 'src/components/Picture'

const TEXT_CONTENT = {
  heading: 'Faça seu empréstimo e aproveite seu cartão Inter Black recheado de benefícios!',
  body: 'Ao contratar o seu empréstimo como pessoa física, você aproveita as vantagens de se tornar um cliente Inter One!',
  cards: [
    { text: '1 ponto Loop a cada R$2,50 gastos' },
    { text: 'Proteção para roubo em caixa eletrônico' },
    { text: 'Seguro Compra Protegida' },
    { text: 'Sala VIP em Aeroportos' },
    { text: 'Assessoria que cuida de tudo pra você' },
    { text: 'Seguro médico em viagens' },
  ],
  cta: 'Garantir meu cartão Inter Black',
}

const FacaSeuEmprestimo = ({ width, sendDataLayerEvent, id, dataLayerSection }: HomeEquitySectionsProps) => {
  const url = 'https://imobiliario.inter.co/home-equity/?campanha=site-pf?tipoSimulacao=1&valorTotal=70000.00&familia=home_equity&origem=site&gaid=GA1.1.951738362.1715344619#/voce-tem-imovel'
  const handleCtaClick = () => {
    sendDataLayerEvent({
      section: dataLayerSection,
      element_action: 'click button',
      element_name: TEXT_CONTENT.cta,
      section_name: TEXT_CONTENT.heading,
      redirect_url: url,
    })
  }

  const getBackgroundSize = () => {
    if (width >= WIDTH_MD && width < WIDTH_LG) {
      return '67%'
    }

    return '62%'
  }
  return (
    <SectionComponent
      id={id}
      bgColor={grayscale[100]}
      bgTablet='https://central-imagens.bancointer.com.br/images-without-small-versions/he-black-768/image.webp'
      bgDesktopXL='https://central-imagens.bancointer.com.br/images-without-small-versions/he-black-1440/image.webp'
      bgDesktopXXL='https://central-imagens.bancointer.com.br/images-without-small-versions/he-black-1440/image.webp'
      bgDesktopLG='https://central-imagens.bancointer.com.br/images-without-small-versions/he-black-1024/image.webp'
      backgroundSize={getBackgroundSize()}
      backgroundPosition='150% 30%'
      minHeight={{ sm: '836px', md: '624px', lg: '504px', xl: '731px', xxl: '731px' }}
    >
      {width < WIDTH_MD && (
        <div className='col-12 col-md-6 order-md-2'>
          <Picture
            imgClassName='mb-3'
            width='100%'
            height='auto'
            src='https://central-imagens.bancointer.com.br/images-without-small-versions/he-black-360/image.webp'
            alt='Vários cartões da conta Inter Black dispostos em forma de semi-espiral, um atrás do outro.'
            minHeight='238px'
          />
        </div>
      )}
      <div className='col-12 col-md-6 col-lg-7 col-xl-8 order-md-1'>
        <H2Citrina
          className='mb-3'
          fontSize={{ sm: '24px', md: '24px', lg: '28px', xl: '48px' }}
          lineHeight={{ sm: '28px', md: '28px', lg: '32px', xl: '52px' }}
        >{TEXT_CONTENT.heading}
        </H2Citrina>
        <InterP
          fontSize={{ lg: '18px', xl: '18px' }}
          lineHeight={{ lg: '22px', xl: '22px' }}
        >{TEXT_CONTENT.body}
        </InterP>
        <S.ListModificator>
          {TEXT_CONTENT.cards.map((card, index) => (
            <li key={index} className='list-item'>
              {card.text}
            </li>
          ))}
        </S.ListModificator>
        <S.CtaModificator>
          <NoDecorationLink
            onClick={handleCtaClick}
            href={url}
          >
            <Button fullWidth>{TEXT_CONTENT.cta}</Button>
          </NoDecorationLink>
        </S.CtaModificator>
      </div>
    </SectionComponent>
  )
}

export default withCommonSectionProps(FacaSeuEmprestimo)
