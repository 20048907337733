import React, { ComponentType } from 'react'
import useDataLayer, { DataLayerFunction } from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'

export type WithCommonSectionPropsT = {
  width: number;
  sendDataLayerEvent: DataLayerFunction;
}

function withCommonSectionProps<T extends WithCommonSectionPropsT = WithCommonSectionPropsT> (WrappedComponent: ComponentType<T>) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  const WithCommonSectionComponent = (props: Omit<T, keyof WithCommonSectionPropsT>) => {
    const width = useWidth()
    const [ sendDataLayerEvent ] = useDataLayer()

    return <WrappedComponent width={width} sendDataLayerEvent={sendDataLayerEvent} {...(props as T)} />
  }
  WithCommonSectionComponent.displayName = `withCommonSectionProps(${displayName})`

  return WithCommonSectionComponent
}

export default withCommonSectionProps
