import React from 'react'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import BlogArticlesJSON from './assets/data/blogArticles.json'
import withCommonSectionProps from 'src/hoc/withCommonSectionProps'
import { HomeEquitySectionsProps } from '../..'
import { H2Citrina, H3Citrina } from 'src/components/Text/Headings'
import { BlogCardT } from 'src/components/BlogCard/types'
import { InterP } from 'src/components/Text/BodyText'
import Icon from 'src/components/UI/MarkdownIcon'
import * as S from './style'
import { truncateText } from 'src/utils/text'

const TEXT_CONTENT = {
  heading: 'Tudo sobre o Home Equity',
}

const TudoSobreHomeEquity = ({ sendDataLayerEvent, id, dataLayerSection }: HomeEquitySectionsProps) => {
  const handleClick = (url: string, title: string) => {
    const dataLayerLinkReadMore: IDataLayerParams = {
        section: dataLayerSection,
        section_name: TEXT_CONTENT.heading,
        element_action: 'click banner',
        element_name: title,
        redirect_url: url,
    }

    sendDataLayerEvent(dataLayerLinkReadMore)
  }

  return (
    <section className='py-5' id={id}>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          <div className='col-12'>
            <H2Citrina
              fontSize={{ sm: '24px', md: '24px' }}
              lineHeight={{ sm: '28px', md: '28px' }}
              className='mb-3 text-center'
            >{TEXT_CONTENT.heading}
            </H2Citrina>
          </div>
        </div>
        <div className='row'>
          {
                BlogArticlesJSON.map((item: BlogCardT, index: number) => (
                  <div className='col-12 col-md-4 pt-4' key={id + '_article_' + index}>
                    <S.Card>
                      <div className='card-header'>
                        <div className='card-header__category mb-3'>
                          <span>{item.category}</span>
                        </div>
                        <H3Citrina
                          fontSize={{ md: '20px', lg: '20px', xl: '20px' }}
                          lineHeight={{ md: '24px', lg: '24px', xl: '24px' }}
                          className='mb-2'
                        >{item.title}
                        </H3Citrina>
                        <span className='card-header__date mb-3'>{item.date}</span>
                      </div>
                      <div className='card-body'>
                        <InterP className='card-body__text mb-2'>{truncateText(item.description, 60)}</InterP>
                        <div className='card-link'>
                          <a
                            onClick={() => handleClick(item.link, item.title)}
                            href={item.link}
                            target='_blank'
                            rel='noreferrer'
                            className='text-orange--extra'
                          >Leia mais <Icon width='22.5' height='24.34' color='orange--extra ml-2' icon='navigation/arrow-right' directory='v2' />
                          </a>
                        </div>
                      </div>
                    </S.Card>
                  </div>
                ))
              }
        </div>
      </div>
    </section>
  )
}

export default withCommonSectionProps(TudoSobreHomeEquity)
