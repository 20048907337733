import { breakpoints } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const ListModificator = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin: 0;
    padding: 0;
  }

  & .list-item {
    color: ${grayscale[400]};
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 14px;
    svg {
      height: 24px;
      width: 24px;
      margin-right: 16px;
    }
  }

  @media (min-width: ${breakpoints.md}){
    & .list-item {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 24px;
    }
  }
`

export const CtaModificator = styled.div`
  a {
    width: 100%
  }
`

export const CardModificator = styled.div`
  max-width: 100%;
  margin: 0 auto;

  & > div {
    border-radius: 32px;
  }

  @media (min-width: ${breakpoints.md}){
    max-width: 336px;

    & > div {
      padding: 24px;
    }
  }

  @media (min-width: ${breakpoints.lg}){
    max-width: 454px;
    & > div {
      padding: 40px;
    }
  }
`
