import React from 'react'
import FAQ from 'src/components/Faq'

import pageContext from '../../pageContext.json'
import { H2Citrina } from 'src/components/Text/Headings'
import { grayscale } from 'src/styles/colors'

const TEXT_CONTENT = {
  heading: 'Perguntas frequentes',
}

const FAQHomeEquity = ({ id }: {id: string}) => {
  return (
    <section className='py-5' id={id}>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <H2Citrina
              color={grayscale[400]}
              fontSize={{ sm: '24px', md: '28px', lg: '40px', xl: '40px' }}
              lineHeight={{ sm: '28px', md: '32px', lg: '48px', xl: '58px' }}
              className='mb-4'
            >{TEXT_CONTENT.heading}
            </H2Citrina>
          </div>
          <FAQ
            answerData={pageContext.structuredData.faq}
            columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} className='px-0'
          />
        </div>
      </div>
    </section>
  )
}

export default FAQHomeEquity
