import { breakpoints } from 'src/styles/breakpoints'
import { orange, sand } from 'src/styles/newColors'
import styled from 'styled-components'

export const AccordionModificator = styled.div`
  & .custom-according-color {
    background: ${sand};
    border-radius: 16px;
    box-shadow: 0px 2px 8px 0px #16161614;
    margin-bottom: 24px;
    & [role='button'] > span {
      color: ${orange.extra}!important;
      font-size: 20px !important;
      line-height: 24px !important;
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    & .custom-according-color {
      & [role='button'] > span {
        font-size: 24px !important;
        line-height: 28px !important;
      }
  }
  }
`

export const ChartModificator = styled.div`
  & .chart {
    min-width: 42px;
  }

  & .percentage-product,
  & .percentage--accent {
    font-family: 'Citrina', sans-serif;
    text-align: center;
    font-weight: 700;
  }

  & .percentage-product {
    font-size: 14px  !important;
    line-height: 16px  !important;
  }

  & .text-chart {
    font-family: 'Sora', sans-serif;
    font-size: 14px !important;
    line-height: 16px !important;
    width: fit-content;
  }

  @media (min-width: ${breakpoints.md}) {
    & .chart {
      min-width: 176px;
      border-radius: 16px 16px 0 0;
    }
    & .percentage-product {
      font-size: 20px !important;
      line-height: 24px !important;
    }
    & .percentage-product.percentage--accent {
      font-size: 24px !important;
      line-height: 28px !important;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    & .text-chart {
      font-size: 18px !important;
      line-height: 22px !important;
    }
  }

  @media (min-width: ${breakpoints.xxl}) {
    & .percentage-product{
      font-size: 24px !important;
      line-height: 28px !important;
    }
    & .percentage-product.percentage--accent {
      font-size: 28px !important;
      line-height: 32px !important;
    }
  }
`
