import React, { useState, useEffect } from 'react'

import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import useSessionStorage from 'src/hooks/window/useSessionStorage'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidthResize from 'src/hooks/window/useWidth'
import { WIDTH_XL } from 'src/utils/breakpoints'
import Popup from 'src/components/Popup'
import pageContext from './pageContext.json'

import {
  S1EmprestimoComGarantia,
  S2ContrateSemBurocracia,
  S3SeuImovelGaranteDinheiro,
  S4CreditoComGarantia,
  S5EmprestimoDeAltoValor,
  S6FacaSeuEmprestimo,
  S7FacaAPortabilidade,
  S8TudoSobreHomeEquity,
  S9FaqHomeEquity,
} from './_sections/_index'
import { WithCommonSectionPropsT } from 'src/hoc/withCommonSectionProps'
import { Theme, initTheme } from '@interco/inter-ui'

export type HomeEquitySectionsProps = WithCommonSectionPropsT & {id: string; dataLayerSection: string}

const HomeEquity = () => {
  const windowWidth = useWidthResize(200)
  const domReady = useDomReady()
  const isDesktop = windowWidth > WIDTH_XL
  const [ hasSavedPopupCookie, setHasSavedPopupCookie ] = useSessionStorage('@hide-bancointer-popup-home-equity', false)
  const [ isPopupOpen, setIsPopupOpen ] = useState(false)
  const [ isModalPopupOpen, setIsModalPopupOpen ] = useState(true)

  useEffect(() => {
    initTheme(Theme.PF)
  })

  useEffect(() => {
    !isModalPopupOpen && setHasSavedPopupCookie(true)
  }, [ isModalPopupOpen ])

  const handleOnMouseLeave = () => {
    setIsPopupOpen(true)
  }

  domReady && document.addEventListener('mouseleave', handleOnMouseLeave)

  const PopupExitPage = domReady && !hasSavedPopupCookie && isDesktop && (
    <Modal isModalOpen={isPopupOpen} setIsModalOpen={setIsModalPopupOpen} locationToRender={document.body}>
      <Popup closeModal={() => setIsPopupOpen(false)} setPopup={setHasSavedPopupCookie} pageName='home-equity' />
    </Modal>
  )

  return (
    <Layout pageContext={pageContext}>
      {PopupExitPage}
      <S1EmprestimoComGarantia id='section-emprestimo-com-garantia' dataLayerSection='dobra_01' />
      <S2ContrateSemBurocracia id='section-contrate-sem-burocracia' dataLayerSection='dobra_02' />
      <S3SeuImovelGaranteDinheiro id='section-seu-imovel-garante-dinheiro' dataLayerSection='dobra_03' />
      <S4CreditoComGarantia id='section-credito-com-garantia' dataLayerSection='dobra_04' />
      <S5EmprestimoDeAltoValor id='section-emprestimo-de-alto-valor' dataLayerSection='dobra_05' />
      <S6FacaSeuEmprestimo id='section-faca-seu-emprestimo' dataLayerSection='dobra_06' />
      <S7FacaAPortabilidade id='section-faca-a-portabilidade' dataLayerSection='dobra_07' />
      <S8TudoSobreHomeEquity id='section-blog-home-equity' dataLayerSection='dobra_08' />
      <S9FaqHomeEquity id='section-faq-home-equity' />
    </Layout>
  )
}

export default HomeEquity
