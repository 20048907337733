import { PayrollTypeProps, reducerType, actionProps } from '../types'

const MIN_VALUE = 50000

// rates Fator PMT
export const PAYROLL_TYPE: PayrollTypeProps = {
  home_equity: {
    minValue: 200000,
    maxValue: 3000000,
  },
}

function calculatePercentage (inputValue: number, maxValue: number) {
  const range = Math.round(maxValue - MIN_VALUE)
  return Math.round((inputValue - MIN_VALUE) * 100 / range)
}

function adjustMaxValue (simulationType: string, qtdParcel: number, page: string) {
  return PAYROLL_TYPE[page].maxValue
}

export function initialState (page: string) {
  return {
    simulationType: 'total',
    inputValue: PAYROLL_TYPE[page].minValue,
    resultValue: PAYROLL_TYPE[page].minValue,
    percentage: calculatePercentage(PAYROLL_TYPE[page].minValue, PAYROLL_TYPE[page].maxValue),
    maxValue: PAYROLL_TYPE[page].maxValue,
    page: page,
  }
}

export const actions = {
  setSimulationType: (simulationType: string) => ({
    type: 'SET_SIMULATION_TYPE',
    simulationType,
  }),
  setInputValue: (inputValue: number) => ({
    type: 'SET_INPUT_VALUE',
    inputValue,
  }),
}

export const payrollSimulatorReducer = (state: reducerType, action: actionProps) => {
  switch (action.type) {
    case 'SET_SIMULATION_TYPE':
      return {
        ...state,
        simulationType: action.simulationType,
        inputValue: state.resultValue,
        resultValue: state.inputValue,
        maxValue: adjustMaxValue(action.simulationType, state.qtdParcel, state.page),
      }
    case 'SET_INPUT_VALUE': {
      const _inputValue = Math.min(action.inputValue, state.maxValue)
      return {
        ...state,
        inputValue: _inputValue,
        percentage: calculatePercentage(_inputValue, state.maxValue),
      }
    }
  }
}
