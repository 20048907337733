import React from 'react'
import Picture from 'src/components/Picture'
import { InterP } from 'src/components/Text/BodyText'
import { H2Citrina } from 'src/components/Text/Headings'
import { breakpoints } from 'src/styles/breakpoints'
import { graphite } from 'src/styles/newColors'
import styled from 'styled-components'

const CardWrapper = styled.div`
  padding: 24px 16px;
  min-height: 438px;
  border-radius: 16px;
  color: ${graphite};
  box-shadow: 0px 16px 32px 2px #16161614;
  background: #fff;
  max-width: 265px;

  & .card-img {
    min-height: 116px;
    object-fit: contain;
  }

  @media (min-width: ${breakpoints.md}) {
    max-width: 39%;
    flex-grow: 1;
    min-height: unset;
    height: fit-content;
    padding: 24px;
  }

  @media (min-width: ${breakpoints.lg}) {
    min-height: 530px;
    padding: 30px 40px;
  }

  @media (min-width: ${breakpoints.xl}) {
    min-height: 640px;
  }

`

const OQueEHomeEquityCard = () => {
  const imgUrl = 'https://central-imagens.bancointer.com.br/images-without-small-versions/he-dobra3/image.webp'
  return (
    <CardWrapper>
      <Picture
        imgClassName='mb-2 mb-lg-3 mb-xl-4 card-img'
        height='auto'
        width='100%'
        src={imgUrl}
        minHeight={{ sm: '118px', md: '76px', lg: '118px', xl: '144px' }}
        alt='Mulher negra sorrindo para a tela do celular, sentada no sofá de casa, vestindo calça jeans e moletom cinza.'
      />
      <H2Citrina
        className='mb-2 mb-lg-4'
        fontSize={{ sm: '24px', md: '20px', lg: '28px', xl: '28px' }}
        lineHeight={{ sm: '28px', md: '24px', lg: '32px', xl: '32px' }}
      >
        O que é Home Equity?
      </H2Citrina>
      <InterP fontSize={{ sm: '15px', md: '12px' }} className='mb-3'>
        Home Equity, ou empréstimo com garantia de imóvel, é uma modalidade de crédito na qual você coloca seu imóvel como garantia de pagamento.
      </InterP>
      <InterP fontSize={{ sm: '15px', md: '12px' }}>
        Desse modo, é possível aproveitar taxas de juros menores e créditos mais altos atrelados ao valor do imóvel.
      </InterP>
    </CardWrapper>
  )
}

export default OQueEHomeEquityCard
