import React from 'react'
import withCommonSectionProps from '../../../../../../hoc/withCommonSectionProps'
import { HomeEquitySectionsProps } from '../..'
import SectionComponent from 'src/components/SectionComponent'
import { WIDTH_MD } from 'src/styles/breakpoints'
import { Card } from '@interco/inter-ui/components/Card'
import { H2Citrina } from 'src/components/Text/Headings'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import { Button } from '@interco/inter-ui/components/Button'
import { NoDecorationLink } from '../../style'
import { grayscale } from 'src/styles/colors'
import { InterP } from 'src/components/Text/BodyText'
import * as S from './style'
import Picture from 'src/components/Picture'

const TEXT_CONTENT = {
  heading: 'Empréstimo de alto valor pra usar como quiser',
  body: 'Com o Home Equity, você recebe a partir de R$70 mil pra fazer o que quiser com o dinheiro. ',
  list: [
    {
      Icon: (<IconsSwitch icon='ic_trending_up' lib='interco' customPath='finance/' size='LG' color={grayscale[500]} />),
      text: 'Investir no seu próprio negócio',
    },
    {
      Icon: (<IconsSwitch icon='ic_piggy_bank' lib='interco' customPath='finance/' size='LG' color={grayscale[500]} />),
      text: 'Quitar dívidas mais caras',
    },
    {
      Icon: (<IconsSwitch icon='ic_bank' lib='interco' customPath='travel-mobility/' size='LG' color={grayscale[500]} />),
      text: 'Construir ou reformar seu imóvel',
    },
    {
      Icon: (<IconsSwitch icon='ic_boxes' lib='interco' customPath='shopping/' size='LG' color={grayscale[500]} />),
      text: 'Construir mais patrimônio',
    },
    {
      Icon: (<IconsSwitch icon='ic_add' lib='interco' customPath='action-navigation/' size='LG' color={grayscale[500]} />),
      text: 'E muito mais!',
    },
  ],
  cta: 'Quero aproveitar',
}

const EmprestimoDeAltoValor = ({ width, sendDataLayerEvent, dataLayerSection, id }: HomeEquitySectionsProps) => {
  const url = 'https://imobiliario.inter.co/home-equity/?campanha=site-pf?tipoSimulacao=1&valorTotal=70000.00&familia=home_equity&origem=site&gaid=GA1.1.951738362.1715344619#/voce-tem-imovel'
  const handleCtaClick = () => {
    sendDataLayerEvent({
      section: dataLayerSection,
      element_action: 'click button',
      element_name: TEXT_CONTENT.cta,
      section_name: TEXT_CONTENT.heading,
      redirect_url: url,
    })
  }
  return (
    <SectionComponent
      id={id}
      ContainerStyles='p-0'
      SectionStyles='py-0'
      bgMobile='white'
      bgTablet='https://central-imagens.bancointer.com.br/images-without-small-versions/he-dobra4-768/image.webp'
      bgDesktopLG='https://central-imagens.bancointer.com.br/images-without-small-versions/he-dobra4-1024/image.webp'
      bgDesktopXL='https://central-imagens.bancointer.com.br/images-without-small-versions/he-dobra4/image.webp'
      bgDesktopXXL='https://central-imagens.bancointer.com.br/images-without-small-versions/he-dobra4/image.webp'
      minHeight={{ sm: '640px', md: '636px', lg: '638px', xl: '840px', xxl: '840px' }}
    >
      {width < WIDTH_MD && (
        <div className='col-12'>
          <Picture
            src='https://central-imagens.bancointer.com.br/images-without-small-versions/he-dobra4-360/image.webp'
            srcSm='https://central-imagens.bancointer.com.br/images-without-small-versions/he-dobra4-360/image.webp'
            width='90%'
            height='auto'
            alt='Homem negro de dreads usando terno claro e cachecol laranja, segurando o celular com as duas mãos e sorrindo para a tela.'
            minHeight='155px'
            imgClassName='mx-3'
          />
        </div>
      )}
      <div className='col-12 col-md-6 offset-md-6'>
        <S.CardModificator>
          <Card useShadow={false}>
            <H2Citrina
              className='mb-3'
              fontSize={{ sm: '24px', md: '24px', lg: '28px', xl: '48px' }}
              lineHeight={{ sm: '28px', md: '28px', lg: '32px', xl: '52px' }}
            >{TEXT_CONTENT.heading}
            </H2Citrina>
            <InterP
              color={grayscale[400]}
              className='mb-3'
            >
              {TEXT_CONTENT.body}
            </InterP>
            <S.ListModificator>
              {TEXT_CONTENT.list.map(({ text, Icon }, index) => (
                <li key={`${id}_listitem_${index}`} className='list-item'>
                  <span>
                    {Icon}
                  </span>
                  <span>
                    {text}
                  </span>
                </li>
          ))}
            </S.ListModificator>
            <S.CtaModificator>
              <NoDecorationLink
                href={url}
                onClick={handleCtaClick}
              >
                <Button fullWidth>
                  {TEXT_CONTENT.cta}
                </Button>
              </NoDecorationLink>
            </S.CtaModificator>
          </Card>
        </S.CardModificator>
      </div>

    </SectionComponent>

  )
}

export default withCommonSectionProps(EmprestimoDeAltoValor)
