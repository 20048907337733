import React from 'react'
import withCommonSectionProps from '../../../../../../hoc/withCommonSectionProps'
import { HomeEquitySectionsProps } from '../..'
import SectionComponent from 'src/components/SectionComponent'
import { H2Citrina } from 'src/components/Text/Headings'
import { InterP } from 'src/components/Text/BodyText'
import styled from 'styled-components'
import { NoDecorationLink } from '../../style'
import { Button } from '@interco/inter-ui/components/Button'
import { grayscale } from 'src/styles/colors'
import Picture from 'src/components/Picture'

const TEXT_CONTENT = {
  heading: 'Faça a portabilidade do seu Crédito com Garantia de Imóvel',
  body: 'Traga seu contrato para o Inter e aproveite as menores taxas do mercado. Você pode economizar e reduzir o valor das parcelas do seu empréstimo atual.',
  cta: 'Quero trazer meu contrato',
}

const CtaModificator = styled.div`
  a {
    width: 100%;
  }
`

const FacaAPortabilidade = ({ sendDataLayerEvent, dataLayerSection, id }: HomeEquitySectionsProps) => {
  const url = 'https://imobiliario.inter.co/portabilidade-home-equity'
  const handleCtaClick = () => {
    sendDataLayerEvent({
      section: dataLayerSection,
      element_action: 'click button',
      element_name: TEXT_CONTENT.cta,
      section_name: TEXT_CONTENT.heading,
      redirect_url: url,
    })
  }
  return (
    <SectionComponent
      id={id}
      minHeight={{ sm: '675px', md: '404px', lg: '470px', xl: '686px', xxl: '686px' }}
    >
      <div className='col-12 col-md-6'>
        <Picture
          srcSm='https://central-imagens.bancointer.com.br/images-without-small-versions/he-dobra7-360/image.webp'
          srcMd='https://central-imagens.bancointer.com.br/images-without-small-versions/he-dobra7-768/image.webp'
          srcLg='https://central-imagens.bancointer.com.br/images-without-small-versions/he-dobra7-1024/image.webp'
          srcXl='https://central-imagens.bancointer.com.br/images-without-small-versions/he-dobra7-1440/image.webp'
          src='https://central-imagens.bancointer.com.br/images-without-small-versions/he-dobra7-1440/image.webp'
          alt='Tela do aplicativo Inter mostrando a tela de economia na portabilidade, com uma economia de R$ 5.500 na primeira parcela do contrato.'
          imgClassName='mb-3 d-block mx-auto'
          width='90%'
          height='auto'
          minHeight={{ sm: '296px', md: '296px', lg: '374px', xl: '494px' }}
        />
      </div>
      <div className='col-12 col-md-6'>
        <H2Citrina
          className='mb-3'
          fontSize={{ sm: '24px', md: '24px', lg: '28px', xl: '48px' }}
          lineHeight={{ sm: '28px', md: '28px', lg: '32px', xl: '52px' }}
        >{TEXT_CONTENT.heading}
        </H2Citrina>
        <InterP
          color={grayscale[400]}
          fontSize={{ md: '18px', lg: '18px', xl: '18px' }}
          lineHeight={{ md: '22px', lg: '22px', xl: '22px' }}
          className='mb-3 mt-2'
        >{TEXT_CONTENT.body}
        </InterP>
        <CtaModificator>
          <NoDecorationLink
            className='mt-2'
            onClick={handleCtaClick}
            href={url}
          >
            <Button fullWidth>{TEXT_CONTENT.cta}</Button>
          </NoDecorationLink>
        </CtaModificator>
      </div>
    </SectionComponent>
  )
}

export default withCommonSectionProps(FacaAPortabilidade)
