import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'
import graphism_sm from './assets/graphism_sm.png'

export const GraphismBg = styled.div`
  background-image: url(${graphism_sm});
  background-size: 192px 256px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 50%;
  padding: 60px 0;
  @media ${device.desktopLG} {
    background-size: 267px 356px;
  }

  @media ${device.desktopXL} {
    background-size: 311px 415px;
    padding: 60px 0;
  }
`
