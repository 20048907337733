import React, { useState } from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import classnames from 'classnames'
import * as S from './style'
import { orange } from 'src/styles/colors'

type RenderChildrenFn = {
  (Title: JSX.Element, SubTitle: JSX.Element, BodyText: JSX.Element, isOpen: boolean): JSX.Element;
}

const RevealMoreCard = ({ children, showLoadingBG = false }: {children: RenderChildrenFn; showLoadingBG?: boolean}) => {
  const [ isOpen, setIsOpen ] = useState(false)

  const Title = ({ children, className }: {children: React.ReactNode; className?: string}) => {
    return <h3 className={`card-title ${className}`}>{children}</h3>
  }
  const SubTitle = ({ children, className }: {children: React.ReactNode; className?: string}) => {
    return <p className={`card-subtitle ${className}`}>{children}</p>
  }
  const BodyText = ({ children, className }: {children: React.ReactNode; className?: string}) => {
    return <p className={`card-body ${className}`}>{children}</p>
  }

  return (
    <S.Card
      className={classnames({ 'animated-background': showLoadingBG })}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      { isOpen && <S.CardShadow />}
      <S.CircleWrapper>
        <S.CircleBtn
          className={classnames({ 'btn--open': isOpen, 'btn--close': !isOpen })}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <OrangeIcon
            size='SM'
            color={isOpen ? '#fff' : orange[500]}
            icon={isOpen ? 'exit' : 'add'}
          />
        </S.CircleBtn>
      </S.CircleWrapper>
      {children(Title, SubTitle, BodyText, isOpen)}
    </S.Card>
  )
}

export default RevealMoreCard
