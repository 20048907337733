import { breakpoints } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const NoDecorationLink = styled.a`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  margin: 0 auto;
  display: block;
  @media (min-width: ${breakpoints.md}) {
    width: 612px;
  }
`
