import React from 'react'
import withCommonSectionProps from '../../../../../../hoc/withCommonSectionProps'
import { HomeEquitySectionsProps } from '../..'
import SectionComponent from 'src/components/SectionComponent'
import { orange, sand } from 'src/styles/newColors'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { WIDTH_SM } from 'src/styles/breakpoints'
import SeuImovelGaranteDinheiroCard from './components/_SeuImovelGaranteDinheiroCard'
import OQueEHomeEquityCard from './components/_OQueEHomeEquityCard'

const SeuImovelGaranteDinheiro = ({ width, sendDataLayerEvent, dataLayerSection, id }: HomeEquitySectionsProps) => {
  return (
    <SectionComponent
      id={id}
      bgColor={sand}
      minHeight={{ sm: '558px', md: '465px', lg: '626px', xl: '832px', xxl: '832px' }}
    >
      <div className='col-12'>
        {width <= WIDTH_SM && (<SeuImovelGaranteDinheiro.CarrouselView sendDataLayerEvent={sendDataLayerEvent} dataLayerSection={dataLayerSection} />)}
        {width > WIDTH_SM && (<SeuImovelGaranteDinheiro.DefaultView sendDataLayerEvent={sendDataLayerEvent} dataLayerSection={dataLayerSection} />)}
      </div>
    </SectionComponent>
  )
}

SeuImovelGaranteDinheiro.CarrouselView = function CarrouselView ({ sendDataLayerEvent, dataLayerSection }: Pick<HomeEquitySectionsProps, 'sendDataLayerEvent' | 'dataLayerSection'>) {
  return (
    <DefaultCarousel
      sm={{ items: 1, partialVisibilityGutter: 30 }}
      customDotColor={orange.extra}
    >
      <li>
        <SeuImovelGaranteDinheiroCard sendDataLayerEvent={sendDataLayerEvent} dataLayerSection={dataLayerSection} />
      </li>
      <li>
        <OQueEHomeEquityCard key='card_02' />
      </li>
    </DefaultCarousel>
  )
}

SeuImovelGaranteDinheiro.DefaultView = function DefaultView ({ sendDataLayerEvent, dataLayerSection }: Pick<HomeEquitySectionsProps, 'sendDataLayerEvent' | 'dataLayerSection'>) {
  return (
    <div className='d-flex flex-row'>
      <SeuImovelGaranteDinheiroCard sendDataLayerEvent={sendDataLayerEvent} dataLayerSection={dataLayerSection} />
      <OQueEHomeEquityCard />
    </div>
  )
}

export default withCommonSectionProps(SeuImovelGaranteDinheiro)
