import React from 'react'
import withCommonSectionProps from '../../../../../../hoc/withCommonSectionProps'
import { HomeEquitySectionsProps } from '../..'

import SectionComponent from 'src/components/SectionComponent'
import { InterP } from 'src/components/Text/BodyText'
import RevealMoreCard from 'src/components/UI/RevealMoreCard'
import * as S from './style'
import { ContrateSemBurocraciaCardsT } from './types'
import { Button } from '@interco/inter-ui/components/Button'
import { WIDTH_MD, WIDTH_XL, WIDTH_XXL, WIDTH_XXLL, WIDTH_XXXL } from 'src/styles/breakpoints'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { orange } from 'src/styles/newColors'
import { grayscale } from 'src/styles/colors'
import { NoDecorationLink } from '../../style'
import Picture from 'src/components/Picture'
import { H2Citrina } from 'src/components/Text/Headings'
import useWidth from 'src/hooks/window/useWidth'
import styled from 'styled-components'

const TEXT_CONTENT = {
  heading: 'Contrate seu Home Equity sem burocracias e idas ao cartório',
  body: 'Confira como funciona o processo de contratação do empréstimo com garantia de imóvel pelo Inter:',
  cta: 'Simular Home Equity',
}

const contrateSemBurocraciaCards: ContrateSemBurocraciaCardsT[] = [
  {
    title: 'Simulação online',
    subtitle: 'Etapa 1:',
    body: 'Preencha os dados do seu imóvel e simule seu crédito.',
    img: 'https://central-imagens.bancointer.com.br/images-without-small-versions/he-dobra2-card1/image.webp',
    alt: 'Mão segurando um celular exibindo a tela inicial do produto Home Equity do Inter, mostrando informações e recursos para a obtenção de empréstimo com garantia de imóvel.',
  },
  {
    title: 'Análise de crédito',
    subtitle: 'Etapa 2:',
    body: 'Envie seus documentos e nós analisamos sua proposta de forma flexível.',
    img: 'https://central-imagens.bancointer.com.br/images-without-small-versions/he-dobra2-card2/image.webp',
    alt: 'Mulher sorrindo segurando um celular com camisa branca, cabelos curtos e ruivos. O fundo apresenta o interior de uma casa, criando uma sensação de ambiente doméstico.',
  },
  {
    title: 'Avaliação do imóvel',
    subtitle: 'Etapa 3:',
    body: 'Conferimos o valor e as condições do imóvel sem burocracias.',
    img: 'https://central-imagens.bancointer.com.br/images-without-small-versions/he-dobra2-card3/image.webp',
    alt: 'Mulher de cabelos afro sentada segurando um tablet e apontando para a tela, outra mulher sorrindo ao seu lado, que é branca, tem cabelos castanhos e tamanho médio, e está apoiada em uma mesa.',
  },
  {
    title: 'Emissão do contrato',
    subtitle: 'Etapa 4:',
    body: 'Análise das condições e assinatura digital do contrato.',
    img: 'https://central-imagens.bancointer.com.br/images-without-small-versions/he-dobra2-card4/image.webp',
    alt: 'Homem branco de cabelos curtos e castanhos sentado em uma mesa de madeira no interior de sua casa com um computador. Ele segura um celular sorrindo para a tela com a mão esquerda.',
  },
  {
    title: 'Registro eletrônico',
    subtitle: 'Etapa 5:',
    body: 'Após a assinatura, realizamos o registro no cartório por você.',
    img: 'https://central-imagens.bancointer.com.br/images-without-small-versions/he-dobra2-card5/image.webp',
    alt: 'Captura de tela do aplicativo Inter, exibindo a linha do tempo do acompanhamento de proposta de Home Equity na etapa de emissão de contrato, com o status "em desenvolvimento"',
  },
  {
    title: 'Dinheiro liberado',
    subtitle: 'Etapa 6:',
    body: 'Em até 24h após o registro, o seu dinheiro será liberado na conta!',
    img: 'https://central-imagens.bancointer.com.br/images-without-small-versions/he-dobra2-card6/image.webp',
    alt: 'Homem de camisa azul sorrindo para a tela do celular no interior de sua casa.',
  },
]

const ContrateSemBurocracia = ({ width, sendDataLayerEvent, id, dataLayerSection }: HomeEquitySectionsProps) => {
  const url = 'https://imobiliario.inter.co/home-equity/?campanha=site-pf?tipoSimulacao=1&valorTotal=70000.00&familia=home_equity&origem=site&gaid=GA1.1.951738362.1715344619#/voce-tem-imovel'
  const handleCtaClick = () => {
    sendDataLayerEvent({
      section: dataLayerSection,
      element_action: 'click button',
      element_name: TEXT_CONTENT.cta,
      section_name: TEXT_CONTENT.heading,
      redirect_url: url,
    })
  }
  const showCtaBtnConditions = (width < WIDTH_MD || width >= WIDTH_XL)
  return (
    <SectionComponent
      id={id}
      ContainerStyles='p-0'
      SectionStyles='p-0'
      minHeight={{ sm: '1436px', md: '491px', lg: '508px', xl: '804px', xxl: '804px' }}
    >
      <div className='col-12 px-4'>
        <H2Citrina
          fontSize={{ sm: '24px', md: '24px' }}
          lineHeight={{ sm: '28px', md: '28px' }}
          className='mb-3 mt-5'
        >{TEXT_CONTENT.heading}
        </H2Citrina>
        <InterP className='mb-4 mb-md-0' color={grayscale[400]}>{TEXT_CONTENT.body}</InterP>
      </div>
      <div className='col-12'>
        { width < WIDTH_MD && (<ContrateSemBurocracia.ListView />)}
        { width >= WIDTH_MD && (<ContrateSemBurocracia.CarrouselView />)}
        { showCtaBtnConditions && (
          <div className='p-4'>
            <NoDecorationLink
              href={url}
              target='_blank'
              rel='noreferrer'
              onClick={handleCtaClick}
            >
              <Button fullWidth>
                {TEXT_CONTENT.cta}
              </Button>
            </NoDecorationLink>
          </div>
        )}
      </div>
    </SectionComponent>
  )
}

ContrateSemBurocracia.ListView = function ListView () {
  return contrateSemBurocraciaCards.map((card, index) => (
    <S.ContrateSemBurocraciaCard key={index}>
      <RevealMoreCard showLoadingBG>
        {(Title, SubTitle, BodyText, isOpen) => (
          <>
            <Picture
              src={card.img}
              height='100%'
              width='100%'
              alt={card.title}
              minHeight='186px'
              imgClassName='card-img'
            />
            <div className='card-body--align-end'>
              {!isOpen && (<SubTitle className='mb-3'>{card.subtitle}</SubTitle>)}
              <Title>{card.title}</Title>
              {isOpen && (<BodyText>{card.body}</BodyText>)}
            </div>
          </>
        )}
      </RevealMoreCard>
    </S.ContrateSemBurocraciaCard>
  ))
}

ContrateSemBurocracia.CarrouselView = function CarrouselView () {
  const width = useWidth()
  const getXLVisibilityGutter = () => {
    if (width >= WIDTH_XXXL) {
      return 180
    }

    if (width >= WIDTH_XXLL) {
      return 200
    }

    return 80
  }
  return (
    <S.CarouselModifier>
      <DefaultCarousel
        md={{ items: 2, partialVisibilityGutter: 55 }}
        lg={{ items: 2, partialVisibilityGutter: 77 }}
        xl={{ items: 2, partialVisibilityGutter: getXLVisibilityGutter() }}
        customDotColor={orange.extra}
      >
        {contrateSemBurocraciaCards.map((card, index) => (
          <S.ContrateSemBurocraciaCard key={index}>
            <RevealMoreCard showLoadingBG>
              {(Title, SubTitle, BodyText, isOpen) => (
                <>
                  <Picture
                    src={card.img}
                    height='100%'
                    width='100%'
                    alt={card.alt}
                    minHeight={{ md: '232px', lg: '260px', xl: '300px' }}
                    imgClassName='card-img'
                  />
                  <div className='card-body--align-end'>
                    {!isOpen && (<SubTitle className='mb-3'>{card.subtitle}</SubTitle>)}
                    <Title>{card.title}</Title>
                    {isOpen && (<BodyText>{card.body}</BodyText>)}
                  </div>
                </>
        )}
            </RevealMoreCard>
          </S.ContrateSemBurocraciaCard>
  ))}
      </DefaultCarousel>
    </S.CarouselModifier>
  )
}

export default withCommonSectionProps(ContrateSemBurocracia)
