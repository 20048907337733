/* eslint-disable react/jsx-no-bind */
import React, { useReducer, useEffect, useState, ChangeEvent } from 'react'
import { payrollSimulatorReducer, initialState, actions, PAYROLL_TYPE } from './PayrollSimulator.reducer'
import { getParameterByName, getCookie, sendDataLayerEvent } from 'src/shared/helpers'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { PayrollSimulatorProps, StateProps } from '../types'
import { Button } from '@interco/inter-ui/components/Button'
import { BoxSimulador, ValueWrapper, Cifra, InputValue, ValueDescription } from './style'
import { H2Citrina } from 'src/components/Text/Headings'

function currencyToFloat (source: string, currencyNumber: string) {
  const result =
    currencyNumber === ''
      ? 0
      : parseInt(currencyNumber.replace(/\D/g, '')) / 100
  return source === 'range' ? result * 100 : result
}

function floatToCurrency (floatNumber: number) {
  return floatNumber.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

const PayrollSimulatorCitrina = ({ page, isPj, sectionNumber, sectionName }: PayrollSimulatorProps) => {
  const [ state, dispatch ] = useReducer(payrollSimulatorReducer, initialState(page))

  const { simulationType, inputValue }: StateProps = state
  const [ gclid, setGclid ] = useState<string | string[] | null | undefined>(null)
  const [ fbclid, setFbclid ] = useState<string | string[] | null | undefined>(null)
  const [ gaid, setGaid ] = useState<string | string[] | null | undefined>(null)
  const [ utmSource, setUtmSource ] = useState<string | string[] | null | undefined>(null)
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  const [ utmContent, setUtmContent ] = useState<string | string[] | null | undefined>(null)
  const [ utmTerm, setUtmTerm ] = useState<string | string[] | null | undefined>(null)
  const [ sendDatalayerEvent ] = useDataLayer()
  const familyType = `${page}`

  const urlPJ = 'https://imobiliario.inter.co/home-equity-pj'
  const urlPF = 'https://imobiliario.inter.co/home-equity/'

  const inputMaxValue = PAYROLL_TYPE[page].maxValue
  const inputMinValue = PAYROLL_TYPE[page].minValue
  const isLessThanMin = inputValue < inputMinValue

  function changeInput (evt: ChangeEvent<HTMLInputElement>) {
    const inputName = evt.target.name
    let inputValue = evt.target.value

    if (simulationType === 'total') {
      if (currencyToFloat(inputName, inputValue) > inputMaxValue) {
        inputValue = `${inputMaxValue}00`
      }
    }

    const insertValue = currencyToFloat(inputName, inputValue)
    dispatch(actions.setInputValue(insertValue))
  }

  const dataLayerCtaSimularMeuEmprestimo: IDataLayerParams = {
    section: sectionNumber,
    section_name: sectionName,
    element_action: 'click button',
    element_name: 'Simular Meu Empréstimo',
    step: '0',
    redirect_url: isPj ? urlPJ : urlPF,
  }

  useEffect(() => {
    setGclid(getParameterByName('gclid', ''))
    setFbclid(getParameterByName('fbclid', ''))
    setGaid(getCookie('_ga'))
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmContent(getParameterByName('utm_content', ''))
    setUtmTerm(getParameterByName('utm_term', ''))
  }, [])

  function handleButtonClick () {
    const parametersGclid = gclid ? `&gclid=${gclid}` : ''
    const parametersFbclid = fbclid ? `&fbclid=${fbclid}` : ''
    const parametersGaid = gaid ? `&gaid=${gaid}` : ''
    const parametersUtmSource = utmSource ? `&utm_source=${utmSource}` : ''
    const parametersUtmMedium = utmMedium ? `&utm_medium=${utmMedium}` : ''
    const parametersUtmCampaign = utmCampaign ? `&utm_campaign=${utmCampaign}` : ''
    const parametersUtmContent = utmContent ? `&utm_content=${utmContent}` : ''
    const parametersUtmTerm = utmTerm ? `&utm_term=${utmTerm}` : ''

    sendDataLayerEvent({ event: 'click_simulacao' })

    if (typeof window !== 'undefined') {
      const url = `${isPj ? urlPJ : urlPF}?campanha=site-pf?tipoSimulacao=1&valorTotal=${inputValue.toFixed(
        2,
      )}&familia=${familyType}&origem=site${parametersGaid}${parametersGclid}${parametersFbclid}${parametersUtmSource}${parametersUtmMedium}${parametersUtmCampaign}${parametersUtmContent}${parametersUtmTerm}`
      window.location.href = url
      sendDatalayerEvent(dataLayerCtaSimularMeuEmprestimo)
    }
  }

  return (
    <BoxSimulador isPj={isPj}>
      <div className='options mr-lg-0'>
        <div className='row'>
          <div className='w-100'>
            <H2Citrina
              className='fw-700 mb-3'
              fontSize={{ sm: '20px', md: '20px', lg: '24px', xl: '28px' }}
              lineHeight={{ sm: '24px', md: '24px', lg: '30px', xl: '22px' }}
            >
              De quanto você precisa?
            </H2Citrina>

            <ValueWrapper className='d-flex justify-content-center align-items-center mx-auto'>
              <Cifra isError={isLessThanMin} className='mr-1'>R$</Cifra>
              <InputValue
                isError={isLessThanMin}
                type='text'
                name='valor'
                value={floatToCurrency(inputValue)}
                onChange={changeInput}
              />
            </ValueWrapper>

            <ValueDescription
              isError={isLessThanMin}
            >
              {isLessThanMin
                ? 'O valor mínimo é de R$200.000,00'
                : 'O valor precisa ser de até 60% do seu imóvel.'}
            </ValueDescription>
          </div>

          <div className='w-100 mx-auto justify-content-center text-center pt-2'>
            <Button
              fullWidth
              onClick={handleButtonClick}
              className='buttonSend fw-600'
              disabled={isLessThanMin}
            >
              Simular meu empréstimo
            </Button>
          </div>
        </div>
      </div>
    </BoxSimulador>
  )
}

export default PayrollSimulatorCitrina
