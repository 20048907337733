import React from 'react'
import withCommonSectionProps from '../../../../../../hoc/withCommonSectionProps'
import { HomeEquitySectionsProps } from '../..'
import SectionComponent from 'src/components/SectionComponent'
import { H2Citrina, H3Citrina } from 'src/components/Text/Headings'
import { Accordion } from '@interco/inter-ui/components/Accordion'
import { NoDecorationLink } from '../../style'
import { Button } from '@interco/inter-ui/components/Button'
import { orange } from 'src/styles/newColors'
import AnimatedChart from 'src/components/AnimatedChart'
import chartData from './chart-credito-com-garantia.json'
import * as S from './style'

const TEXT_CONTENT = {
  heading: 'O Crédito com Garantia tem as as menores taxas do mercado',
  subHeading: 'Temos as melhores condições de taxas e parcelas',
  cards: [
    { title: 'Taxa pós-fixada', body: 'A partir de 1,09% a.m. + IPCA  e até 240 meses. Essa taxa varia conforme a flutuação do IPCA' },
    { title: 'Taxa pré-fixada', body: 'A partir de 1,5% a.m. em até 120 meses. Essa taxa é fixada no momento do contrato' },
  ],
  cta: 'Simular Home Equity',
}

const CreditoComGarantia = ({ sendDataLayerEvent, dataLayerSection, id }: HomeEquitySectionsProps) => {
  const url = 'https://imobiliario.inter.co/home-equity/?campanha=site-pf?tipoSimulacao=1&valorTotal=70000.00&familia=home_equity&origem=site&gaid=GA1.1.951738362.1715344619#/voce-tem-imovel'
  const handleCtaClick = () => {
    sendDataLayerEvent({
      section: dataLayerSection,
      element_action: 'click button',
      element_name: TEXT_CONTENT.cta,
      section_name: TEXT_CONTENT.heading,
      redirect_url: url,
    })
  }
  return (
    <SectionComponent
      id={id}
      minHeight={{ sm: '911px', md: '698px', lg: '708px', xl: '921px', xxl: '921px' }}
    >
      <div className='col-12'>
        <H2Citrina
          className='text-center'
          fontSize={{ sm: '24px', md: '24px', lg: '28px', xl: '48px' }}
          lineHeight={{ sm: '28px', md: '28px', lg: '32px', xl: '52px' }}
          color={orange.extra}
        >{TEXT_CONTENT.heading}
        </H2Citrina>
        <div className='mb-5'>
          <S.ChartModificator>
            <AnimatedChart id='section-credito-com-garantia' contentData={chartData} />
          </S.ChartModificator>
        </div>
      </div>
      <div className='col-12'>
        <H3Citrina
          className='text-center mb-3'
          color={orange.extra}
          fontSize={{ sm: '20px', md: '20px', lg: '24px', xl: '28px' }}
          lineHeight={{ sm: '24px', md: '24px', lg: '30px', xl: '32px' }}
        >{TEXT_CONTENT.subHeading}
        </H3Citrina>
        <div className='row p-0'>
          {TEXT_CONTENT.cards.map((card, index) => {
            const [ isExpanded, setIsExpanded ] = React.useState(true)
            return (
              <div className='col-12 col-md-6' key={index}>
                <S.AccordionModificator>
                  <Accordion
                    className='custom-according-color CUS'
                    expanded={isExpanded}
                    title={card.title}
                    onClick={() => setIsExpanded(!isExpanded)}
                  >
                    <p>{card.body}</p>
                  </Accordion>
                </S.AccordionModificator>
              </div>
            )
          },
          )}
        </div>
        <div>
          <NoDecorationLink
            href={url}
            onClick={handleCtaClick}
          >
            <Button fullWidth>
              {TEXT_CONTENT.cta}
            </Button>
          </NoDecorationLink>
        </div>
      </div>
    </SectionComponent>
  )
}

export default withCommonSectionProps(CreditoComGarantia)
