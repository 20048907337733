import { breakpoints, device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const ContrateSemBurocraciaCard = styled.div`
  min-height: 186px;
  width: 100%;
  position: relative;
  & .card-img {
    position: absolute;
    top: 0;
    left: -20px;
    width: 110%;
    object-fit: cover;
  }

  & .card-body--align-end {
    display: flex;
    height: 100%;
    flex-grow: 1;
    justify-content: flex-end;
    flex-direction: column;
  }

  @media (min-width: ${breakpoints.md}) {
    max-width: 288px;
    border-radius: 16px;
    overflow: hidden;
      & .card-img {
        top: 0;
        left: 0;
        width: 100%;
      }
  }

  @media (min-width: ${breakpoints.lg}) {
    max-width: 380px;
    min-height: 260px;
  }

  @media (min-width: ${breakpoints.xl}) {
    max-width: 480px;
    min-height: 300px;
  }
`

export const CarouselModifier = styled.div`
  & .react-multiple-carousel__arrow {
    bottom: -10px;
    width: fit-content;
  }

  & .react-multiple-carousel__arrow--right {
    right: 16.5rem;
  }

  & .react-multiple-carousel__arrow--left {
    left: 16.5rem;
  }

  @media ${device.desktopLG} {
    & .react-multiple-carousel__arrow--right {
      right: 24.5rem;
    }

    & .react-multiple-carousel__arrow--left {
      left: 24.5rem;
    }
  }

  @media ${device.desktopXL} {
    & .react-multiple-carousel__arrow--right {
      right: 30.5rem;
    }

    & .react-multiple-carousel__arrow--left {
      left: 30.5rem;
    }
  }

  @media ${device.desktopXXXL} {
    & .react-multiple-carousel__arrow--right {
      right: 39.5rem;
    }

    & .react-multiple-carousel__arrow--left {
      left: 39.5rem;
    }
  }

`
